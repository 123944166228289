<template>
    <section>
        <div id="renew-multi-contract-modal" class="uk-modal-container uk-flex-top renew-multi-contract-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-bold uk-text-center">Renew Contract</h2>
                </div>
                <div class="uk-modal-body" uk-overflow-auto>
                    <form>
                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <p class="uk-margin-remove">You are going to renew the contract of this mitra!</p>
                                <p class="uk-margin-small-top uk-text-bold uk-text-lead">
                                    {{ totalWorkers.length > 0 ? totalWorkers.map(d => d.fullname).join(", ") : 'Mitra MyRobin' }}
                                </p>
                            </div>
                            <div class="uk-width-3-5">
                                <label for="contract" class="uk-form-label">Select Contract:</label>
                                <select :disabled="form_renew.is_contract_from_vacancy" id="contract" name="contract" class="uk-select" v-model="form_renew.job_contract_id">
                                    <option value="" selected>Choose Contract...</option>
                                    <option
                                        v-for="(contract, index) in contractOptions"
                                        :key="index"
                                        :value="contract._id"
                                    >{{ contract.title }}</option>
                                </select>
                            </div>
                            <div class="uk-width-expand">
                                <label for="newStartDate" class="uk-form-label">Start Date:</label>
                                <div class="uk-inline uk-width-expand">
                                    <datetime
                                        id="newStartDate"
                                        name="Start Date"
                                        input-class="uk-input uk-border-rounded"
                                        type="date"
                                        v-model="form_renew.new_start_date"
                                    ></datetime>
                                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                </div>
                            </div>
                            <div class="uk-width-expand">
                                <label for="newEndDate" class="uk-form-label">End Date:</label>
                                <div class="uk-inline uk-width-expand">
                                    <datetime
                                        id="newEndDate"
                                        name="End Date"
                                        input-class="uk-input uk-border-rounded"
                                        type="date"
                                        :min-datetime="datetime.fromISO(form_renew.new_start_date).plus({days: 1}).toISODate()"
                                        v-model="form_renew.new_end_date"
                                    ></datetime>
                                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <label>
                                    <input v-model="form_renew.is_contract_from_vacancy" class="uk-checkbox" type="checkbox" @change="onChangeContractVacancy">
                                        Use Contract from Job Vacancy
                                </label>
                            </div>
                            <template v-if="form_renew.is_contract_from_vacancy">
                                <div class="uk-width-1-1">
                                    <div class="contract-vacancy">
                                        <div class="uk-width-1-1 uk-flex-inline" id="title">
                                            <img :src="`${images}/circle-black-info.svg`" alt="info" width="18"><span class="uk-text-bold uk-margin-small-left">Job Vacancy Contract List</span>
                                        </div>
                                        <div class="uk-width-1-1">
                                            <div v-for="(worker, index) in totalWorkers" :key="index">{{ worker.fullname }} - {{ getPartnerJobVacancyContract({_id: worker._id}) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div v-if="form_renew.job_contract_id && !form_renew.is_contract_from_vacancy" class="uk-width-1-1">
                                <label for="contract-content" class="uk-form-label">Contract</label>
                                <div id="contract-content">
                                    <vue-editor v-model="form_renew.contract"></vue-editor>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button
                        type="button"
                        class="uk-button uk-button-default uk-modal-close uk-margin-right button-filter"
                        @click="hideModal"
                    >Cancel</button>
                    <button
                        type="submit"
                        class="uk-button"
                        :class="[disabledButtonRenew ? 'uk-button-default' : 'uk-button-primary']"
                        :disabled="disabledButtonRenew || isLoading"
                        @click="confirmRenewContract"
                    >Send</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { notificationSuccess, notificationDangerCustom } from "@/utils/notification";
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import { VueEditor } from 'vue2-editor';
import { DateTime } from 'luxon';
import { PREFIX_IMAGE } from "@/utils/constant";

export default {
    props: {
        totalWorkers: {
            type: Array,
            default: () => [],
        },
        contractOptions: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Datetime,
        VueEditor,
    },
    data(){
        return {
            images: PREFIX_IMAGE,
            datetime: DateTime,
            isLoading: false,
            form_renew: {
                job_contract_id: '',
                new_start_date: null,
                new_end_date: null,
                contract: null,
                job_partner_ids: this.$props.totalWorkers.map(d => d._id),
                is_contract_from_vacancy: false
            },
            job_vacancy_contracts: null
        };
    },
    mounted() {
        window.UIkit.modal('#renew-multi-contract-modal').show();
    },
    computed: {
        disabledButtonRenew() {
            let disable = false;
            if (!(this.form_renew.job_contract_id || this.form_renew.is_contract_from_vacancy) || !this.form_renew.new_start_date || !this.form_renew.new_end_date) {
                disable = true;
            }
            return disable;
        },
    },
    watch: {
        'form_renew.new_start_date'(val) {
            if (val > this.form_renew.new_end_date) {
                this.form_renew.new_end_date = '';
            }
        },
        'form_renew.job_contract_id'(val) {
            const selectedContract = this.contractOptions.find(contract => contract._id === val);
            this.form_renew.contract = selectedContract.contract;
        },
    },
    methods: {
        ...mapActions({
            renewMultiContractMitra: 'worker/renewMultiContractMitra',
            getJobVacancyContract: 'contract/getJobVacancyContract'
        }),
        hideModal(isReload = false) {
            window.UIkit.modal('#renew-multi-contract-modal').$destroy(true);
            this.$emit("hideModal", isReload);
        },
        async confirmRenewContract() {
            try {
                this.isLoading = true;
                const response = await this.renewMultiContractMitra(this.form_renew);
                this.isLoading = false;
                if (response && response.status === "OK") {
                    notificationSuccess("Mitra contract renewed!");
                    this.hideModal(true);
                } else {
                    notificationDangerCustom("Failed to renew mitra contract.");
                }
            } catch (error) {
                this.hideModal();
                this.isLoading = false;
            }
        },
        async onChangeContractVacancy() {
            if (this.form_renew.is_contract_from_vacancy) {
                this.job_vacancy_contracts = await this.getJobVacancyContract({ job_partner_ids: this.form_renew.job_partner_ids });
                this.form_renew.job_contract_id = '';
                this.form_renew.contract = '';
            } else {
                this.job_vacancy_contracts = null;
            }
        },
        getPartnerJobVacancyContract({_id}) {
            let data = null;
            if (this.job_vacancy_contracts) {
                for (const partner_contract of this.job_vacancy_contracts) {
                    if (partner_contract._id.toString() == _id.toString()) {
                        data = partner_contract.contracts.map(contract => contract.title);
                    }
                }
            }
            if (data) {
                return data.join(", ");
            } else {
                return '-';
            }
        }
    }
};
</script>

<style scoped>
.contract-vacancy {
    background-color: #ECEFF1;
    padding: 1rem;
}
.contract-vacancy > #title {
    border-bottom: 1px solid #B0BEC5;padding-bottom: 1rem;margin-bottom: 1rem;
}
</style>
